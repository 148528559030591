/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import PropTypes from 'prop-types';

import Field from 'Component/Field';
import Form from 'Component/Form';
import TimerButton from 'Component/TimerButton';
import {
    MyAccountCreateAccount as SourceMyAccountCreateAccount
} from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.component';

import {
    CREATE_ACCOUNT,
    CREATE_ACCOUNT_VERIFY_OTP
} from './MyAccountCreateAccount.config';

import './MyAccountCreateAccount.override.style';

/** @namespace Scandipwa/Component/MyAccountCreateAccount/Component/MyAccountCreateAccountComponent */
export class MyAccountCreateAccountComponent extends SourceMyAccountCreateAccount {
  static propTypes = {
      handleCreateAccountOTP: PropTypes.func.isRequired,
      currentCreateRenderForm: PropTypes.func.isRequired,
      onCreateAccountAttempt: PropTypes.func.isRequired,
      handleCreateAccountOTPVerify: PropTypes.func.isRequired,
      handleResendOTP: PropTypes.func.isRequired,
      formFields: PropTypes.func.isRequired,
      isRetryForm: PropTypes.func.isRequired,
      handleRetry: PropTypes.func.isRequired
  };

  renderMap = {
      [CREATE_ACCOUNT]: this.renderSubmitButton.bind(this),
      [CREATE_ACCOUNT_VERIFY_OTP]: this.renderCreateAccountVerifyButton.bind(this)
  };

  renderFormButton() {
      const { currentCreateRenderForm } = this.props;

      return this.renderMap[currentCreateRenderForm]();
  }

  renderForm() {
      const {
          currentCreateRenderForm, onCreateAccountAttempt, handleCreateAccountOTP, handleCreateAccountOTPVerify
      } = this.props;

      if (currentCreateRenderForm === CREATE_ACCOUNT) {
          return (
        <Form
          key="create-account"
          onSubmit={ onCreateAccountAttempt }
          onSubmitSuccess={ handleCreateAccountOTP }
          onSubmitError={ onCreateAccountAttempt }
        >
            { this.renderCreateAccountPersonalInfoFields() }
            { this.renderCreateAccountSignUpInfoFields() }
            { this.renderFormButton() }
        </Form>
          );
      }

      return (
        <Form
          key="create-account-verify"
          onSubmit={ onCreateAccountAttempt }
          onSubmitSuccess={ handleCreateAccountOTPVerify }
          onSubmitError={ onCreateAccountAttempt }
        >
            { this.renderCreateAccountPersonalInfoFields() }
            { this.renderCreateAccountSignUpInfoFields() }
            { this.renderFormButton() }
        </Form>
      );
  }

  renderRetryButton() {
      const { handleResendOTP, isRetryForm, handleRetry } = this.props;

      if (isRetryForm) {
          return (
        <button
          block="Button"
          mix={ { block: 'MyAccountOverlay', elem: 'SignUpButton' } }
          onClick={ handleRetry }
        >
          { __('Retry') }
        </button>
          );
      }

      return (
        <TimerButton onClick={ handleResendOTP }>{ __('Resend Otp') }</TimerButton>
      );
  }

  renderCreateAccountVerifyButton() {
      const { isSubmitted, formFields } = this.props;

      return (
          <>
          <Field
            type="otp"
            label={ __('Enter OTP') }
            id="otp"
            name="otp"
            value={ formFields.otp }
            autocomplete="given-name"
            validation={ ['notEmpty'] }
            isSubmitted={ isSubmitted }
          />
              <div block="MyAccountOverlay" elem="Buttons">
              <p className="emailSendRegistermsq">OTP sent to your mobile and email.</p>
                { this.renderRetryButton() }
                <button
                  block="Button"
                  type="submit"
                  mix={ { block: 'MyAccountOverlay', elem: 'SignUpButton' } }
                >
                  { __('submit') }
                </button>
              </div>
          </>
      );
  }

  renderCreateAccountPersonalInfoFields() {
      const { newsletterActive, isSubmitted, formFields } = this.props;

      return (
            <fieldset block="MyAccountOverlay" elem="Legend">
                <Field
                  type="text"
                  label={ __('First Name') }
                  id="firstname"
                  name="firstname"
                  value={ formFields.firstname }
                  autocomplete="given-name"
                  validation={ ['notEmpty', 'firstName'] }
                  isSubmitted={ isSubmitted }
                />
                <Field
                  type="text"
                  label={ __('Last Name') }
                  id="lastname"
                  name="lastname"
                  value={ formFields.lastname }
                  autocomplete="family-name"
                  validation={ ['notEmpty', 'lastName'] }
                  isSubmitted={ isSubmitted }
                />
                { this.renderVatNumberField() }
                { newsletterActive ? this.renderSubscribeToNewsletter() : null }
            </fieldset>
      );
  }

  renderCreateAccountSignUpInfoFields() {
      const { isSubmitted, formFields, currentCreateRenderForm } = this.props;
      const isVerifyOTP = currentCreateRenderForm === CREATE_ACCOUNT_VERIFY_OTP;
      return (
            <fieldset block="MyAccountOverlay" elem="Legend">
                <Field
                  type="email"
                  label={ __('Email Address') }
                  id="email"
                  name="email"
                  value={ formFields.email }
                  autocomplete="email"
                  validation={ ['notEmpty', 'email'] }
                  isSubmitted={ isSubmitted }
                  mix={ { block: 'Custom', elem: `${isVerifyOTP}` } }
                />
                <Field
                  type="mobileNumber"
                  label={ __('Mobile Number') }
                  id="mobileNumber"
                  name="mobileNumber"
                  value={ formFields.mobileNumber }
                  prepone="+91"
                  validation={ ['notEmpty', 'mobileNumber'] }
                  isSubmitted={ isSubmitted }
                  mix={ { block: 'Custom', elem: `${isVerifyOTP}` } }
                />
                <Field
                  type="password"
                  label={ __('Password') }
                  id="password"
                  name="password"
                  value={ formFields.password }
                  autocomplete="new-password"
                  validation={ ['notEmpty', 'password'] }
                  isSubmitted={ isSubmitted }
                />
                <Field
                  type="password"
                  label={ __('Confirm password') }
                  id="confirm_password"
                  name="confirm_password"
                  value={ formFields.confirm_password }
                  autocomplete="new-password"
                  validation={ ['notEmpty', 'password', 'password_match'] }
                  isSubmitted={ isSubmitted }
                />
            </fieldset>
      );
  }

  renderSubmitButton() {
      return (
          <div block="MyAccountOverlay" elem="Buttons">
              <button
                block="Button"
                type="submit"
                mix={ { block: 'MyAccountOverlay', elem: 'SignUpButton' } }
              >
                  { __('Send Otp') }
              </button>
          </div>
      );
  }

  renderCreateAccountForm() {
      return (
          this.renderForm()
      );
  }

  renderAdditionalField() {
      const { state, handleSignIn } = this.props;

      return (
        <article block="MyAccountOverlay" elem="Additional" mods={ { state } }>
            <section>
                <h4>{ __('Already a Member?') }</h4>
                <button
                  block="Button"
                  mods={ { likeLink: true } }
                  mix={ { block: 'MyAccountOverlay', elem: 'SignInLink' } }
                  onClick={ handleSignIn }
                >
                    { __('Sign in') }
                </button>
            </section>
        </article>
      );
  }
}

export default MyAccountCreateAccountComponent;
